<template>
  <div class="update_container">
    <el-select v-model="shortcut" :filterable="true" data-testid="shortcut">
      <el-option
        v-for="item in shortcutOptions"
        :key="item.key"
        :label="item.value"
        :value="item.key"
        :data-testid="item.key"
      ></el-option>
    </el-select>

    <div class="flex date_container">
      <div class="calendar_start_date">
        <el-date-picker
          :picker-options="startDatePickerOptions"
          v-model="startDate"
          type="date"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
          :clearable="false"
          :editable="false"
          @change="changeStart"
          data-testid="startDate"
          suffix-icon="el-icon-arrow-down"
        >
        </el-date-picker>
      </div>
      <div class="calendar_to">
        <div class="m-auto">{{ $t('common.keys.TO') }}</div>
      </div>
      <div class="calendar_end_date">
        <el-date-picker
          :picker-options="endDatePickerOptions"
          v-model="endDate"
          type="date"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
          :clearable="false"
          :editable="false"
          @change="changeEnd"
          data-testid="endDate"
        >
        </el-date-picker>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    threeMonthsRange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startDate: this.$store.state.behavior.startDate,
      endDate: this.$store.state.behavior.endDate,
      shortcut: this.$store.state.behavior.shortcut,
      shortcutOptions: [
        { key: 1, value: this.$t('common.dateRange.today') },
        { key: 2, value: this.$t('common.dateRange.yesterday') },
        { key: 3, value: this.$t('common.dateRange.lastSevenDays') },
        { key: 4, value: this.$t('common.dateRange.lastThirtyDays') },
        { key: 5, value: this.$t('common.dateRange.weekToDate') },
        { key: 6, value: this.$t('common.dateRange.monthToDate') },
        { key: 7, value: this.$t('common.dateRange.quarterToDate') },
        { key: 8, value: this.$t('common.dateRange.previousWeek') },
        { key: 9, value: this.$t('common.dateRange.previousMonth') },
        { key: 10, value: this.$t('common.dateRange.previousQuarter') }
      ],
      startDatePickerOptions: {},
      endDatePickerOptions: {}
    };
  },
  mounted() {
    this.startDate = this.startDate || this.$moment(new Date(new Date().setDate(1))).format('YYYY-MM-DD 00:00:00');
    this.endDate = this.endDate || this.$moment(new Date()).format('YYYY-MM-DD 23:59:59');
    this.$emit('update:startDate', this.startDate);
    this.$emit('update:endDate', this.endDate);
    this.changeEnd();
    this.changeStart();
  },
  methods: {
    changeStart() {
      if (new Date(this.startDate) > this.endDate) {
        this.endDate = this.startDate;
      }
      this.startDatePickerOptions = Object.assign({}, this.startDatePickerOptions, {
        disabledDate: time => {
          return time < new Date().setFullYear(new Date().getFullYear() - 1) || time > new Date();
        }
      });
    },
    changeEnd() {
      if (new Date(this.endDate) < this.startDate) {
        this.startDate = this.endDate;
      }
      this.endDatePickerOptions = Object.assign(
        {},
        this.endDatePickerOptions,
        this.threeMonthsRange
          ? {
              disabledDate: time => {
                const currentDate = new Date(this.startDate);
                const threeMonthsDiff = new Date(this.startDate);
                const startingDate = new Date(this.startDate);
                startingDate.setDate(currentDate.getDate() - 1);
                threeMonthsDiff.setMonth(currentDate.getMonth() + 3);
                threeMonthsDiff.setDate(currentDate.getDate() - 1);

                return time <= startingDate || time > (threeMonthsDiff || new Date());
              }
            }
          : {
              disabledDate: time => {
                return time < new Date().setFullYear(new Date().getFullYear() - 1) || time > new Date();
              }
            }
      );
    }
  },
  watch: {
    shortcut(newVal) {
      switch (newVal) {
        case 1: {
          this.startDate = new Date();
          this.endDate = this.startDate;
          break;
        }
        case 2: {
          var today = new Date();
          this.endDate = new Date();
          this.startDate = new Date();
          this.endDate.setDate(today.getDate() - 1);
          this.startDate.setDate(today.getDate() - 1);
          break;
        }
        case 3: {
          var today = new Date();
          this.endDate = new Date();
          this.startDate = new Date();
          this.startDate.setDate(today.getDate() - 7);
          break;
        }
        case 4: {
          this.endDate = new Date();
          this.startDate = new Date();
          this.startDate.setDate(this.endDate.getDate() - 30);
          break;
        }
        case 5: {
          this.endDate = new Date();
          var day = this.endDate.getDay(),
            diff = this.endDate.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
          this.startDate = new Date();
          this.startDate.setDate(diff);
          break;
        }
        case 6: {
          this.endDate = new Date();
          this.startDate = new Date(new Date().setDate(1));
          break;
        }
        case 7: {
          this.endDate = new Date();
          let month = new Date().getMonth(); //month range 0 - 11
          let firstDayQuarter = new Date();
          firstDayQuarter.setMonth(parseInt(month / 3) * 3);
          firstDayQuarter.setDate(1);
          this.startDate = new Date(firstDayQuarter);
          break;
        }
        case 8: {
          var day = new Date().getDay(),
            diff = new Date().getDate() - day + (day == 0 ? -6 : 1) - 7; //get Monday of last week
          this.startDate = new Date();
          this.startDate.setDate(diff);
          this.endDate = new Date();
          this.endDate.setDate(diff + 6);
          break;
        }
        case 9: {
          var today = new Date();
          this.endDate = new Date(today.getFullYear(), today.getMonth(), 0); //will get last day of last month
          this.startDate = new Date(today.getFullYear(), today.getMonth() - 1); //will get 1st day of last month
          break;
        }
        case 10: {
          let today = new Date();
          let month = new Date().getMonth(); //month range 0 - 11
          let quarter = parseInt(month / 3) + 1;
          if (quarter == 1) {
            this.endDate = new Date(today.getFullYear() - 1, 11, 31);
            this.startDate = new Date(today.getFullYear() - 1, 9, 1);
          } else if (quarter == 2) {
            this.endDate = new Date(today.getFullYear(), 2, 31);
            this.startDate = new Date(today.getFullYear() - 1, 12, 1);
          } else if (quarter == 3) {
            this.endDate = new Date(today.getFullYear(), 5, 30);
            this.startDate = new Date(today.getFullYear(), 3, 1);
          } else {
            this.endDate = new Date(today.getFullYear(), 8, 30);
            this.startDate = new Date(today.getFullYear(), 6, 1);
          }
          break;
        }
        default:
          break;
      }
      this.startDate = this.$moment(this.startDate).format('YYYY-MM-DD 00:00:00');
      this.endDate = this.$moment(this.endDate).format('YYYY-MM-DD 23:59:59');
      this.$store.commit('behavior/setShortcut', newVal);
    },
    startDate(newVal) {
      newVal = this.$moment(newVal).format('YYYY-MM-DD HH:mm:ss');
      this.$emit('update:startDate', newVal);
      this.$store.commit('behavior/setStartDate', newVal);
    },
    endDate(newVal) {
      newVal = this.$moment(newVal).format('YYYY-MM-DD 23:59:59');
      this.$emit('update:endDate', newVal);
      this.$store.commit('behavior/setEndDate', newVal);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/dateRangePicker.scss';
</style>
